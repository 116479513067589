import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import { motion } from "framer-motion"
import Layout from "../components/Layout"
import PageHeader from "../components/PageHeader"
import Heading from "../components/Heading"
import bannerImg from "./../images/about/banner.jpg"
import img01 from "./../images/about/about-01.jpg"
import img02 from "./../images/about/about-02.jpg"

import {
  imgCol,
  aboutImg01,
  aboutImg02,
  aboutImg03,
  aboutSection02,
} from "./../styles/about.module.scss"
import PageFooter from "../components/PageFooter"

function about({ data }) {
  const headerImg = data.aboutJson.headerImg.childImageSharp.fluid

  return (
    <Layout>
      <Helmet>
        <title>
          Tentang Kami | Grand Wisata Bekasi | Kota Mandiri Terintegrasi dan
          Terencana
        </title>
        <meta
          name="description"
          content="Kehadiran kota mandiri seperti Grand Wisata Bekasi tidak hanya menyediakan fasiltas hunian dan commercial terlengkap di timur Jakarta, namun juga menjadi lokomotif property dan ekonomi di Bekasi."
        />
        <meta
          name="keywords"
          content="grand wisata bekasi, grand wisata property, grand wisata, grand wisata tambun, perumahan grand wisata, sinarmas land"
        />
        <meta name="distribution" content="web" />
        <link
          rel="canonical"
          href="https://www.grandwisataproperty.com/about"
        />
        <meta
          property="og:title"
          content="Tentang Kami | Grand Wisata Bekasi | Kota Mandiri Terintegrasi dan Terencana di Timur Jakarta"
        />
        <meta
          property="og:description"
          content="Kehadiran kota mandiri seperti Grand Wisata Bekasi tidak hanya menyediakan fasiltas hunian dan commercial terlengkap di timur Jakarta, namun juga menjadi lokomotif property dan ekonomi di Bekasi."
        />
      </Helmet>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <PageHeader bg={headerImg}>Tentang Kami</PageHeader>
        <section className="container">
          <div className="row">
            <div
              className={`${imgCol} col-md-6 d-flex justify-content-center align-items-start justify-content-sm-start align-items-sm-center`}
            >
              <div
                className={`${aboutImg01}`}
                style={{ backgroundImage: `url(${img01})` }}
              ></div>
            </div>
            <div className="col-md-6">
              <Heading>Kota Mandiri Terintegrasi dan Terencana</Heading>
              <p>
                Tingginya permintaan property commercial maupun residential di
                dalam kawasan kota mandiri / township, menginisiasi Sinarmas
                Land untuk membangun kota mandiri Grand Wisata Bekasi di timur
                Jakarta yang lengkap dengan fasilitas residential yang asri,
                commercial yang serba lengkap serta menunjang lifestyle masa
                kini.
              </p>
              <p>
                Kehadiran kota mandiri seperti Grand Wisata Bekasi tidak hanya
                menyediakan fasiltas hunian dan commercial terlengkap di timur
                Jakarta, namun juga menjadi lokomotif property dan ekonomi di
                Bekasi.
              </p>
              <p>
                Berada di lahan seluas 1.100 Ha, lokasi Grand Wisata Bekasi
                hanya berjarak 15 menit ke Jakarta dan 5 menit ke kawasan
                ekonomi & industri terpadu terbesar di Indonesia (Kawasan
                Industri MM2100) membuat Grand Wisata Bekasi menjadi kota
                mandiri berprospek tinggi sebagai supporting township bagi 4000
                perusahaan multinasional dari 30 negara.
              </p>
            </div>
          </div>
          <div className={`row mt-sm-5 ${aboutSection02}`}>
            <div className="col-md-6 d-flex justify-content-sm-center align-items-sm-center">
              <div>
                <h4>Konsep TOD (Transport Oriented Development)</h4>
                <p>
                  Grand Wisata terkoneksi dengan infrastruktur seperti akses
                  langsung jalan tol Jakarta Cikampek, JORR I&II, LRT dan KCIC
                  melalui shuttle bus.
                </p>
                <h4>Konsep Low Density Township</h4>
                <p>
                  Grand Wisata turut mengaplikasikan konsep low density township
                  yang membuat Grand Wisata menjadi kawasan residential dengan
                  banyak ruang terbuka hijau, asri dan exclusive yang nyaman dan
                  aman.
                </p>
                <p>
                  Ukuran township yang besar memungkinkan adanya pembangunan
                  beragam fasilitas seperti Mall Living World Grand Wisata,
                  rumah sakit, sekolah berkualitas, modern market, tempat
                  berbisnis dan tempat rekreasi bertaraf internasional di dalam
                  Grand Wisata Bekasi.
                </p>
              </div>
            </div>
            <div
              className={`${imgCol} col-md-6 d-flex justify-content-sm-end align-items-sm-center justify-content-center align-items-start`}
            >
              <div
                className={`${aboutImg02}`}
                style={{ backgroundImage: `url(${img02})` }}
              ></div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <img
                src={bannerImg}
                className={`${aboutImg03}`}
                alt="Sinarmas Land"
              ></img>
              <h4>
                Sinarmas Land Lebih Dari 40 Tahun Berkarya Membangun Indonesia
              </h4>
              <p>
                Sinarmas Land adalah perusahaan developer terbesar di Indonesia
                yang turut serta membangun Indonesia selama 40 tahun di 26 kota
                di Indonesia dan 5 negara.
              </p>
            </div>
          </div>
        </section>
        <PageFooter />
      </motion.div>
    </Layout>
  )
}

export const query = graphql`
  {
    aboutJson {
      headerImg {
        childImageSharp {
          fluid(maxWidth: 6000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`

export default about
